<template>
  <layout :tituloPagina="`Network | Routers | ${router.nombre} | Tablero`">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Tablero de {{router.nombre}}
            </h4>
            <div>
              <a href="#" onclick="return false;" @click="cerrar()">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="input-group">
              <span class="input-group-text">
                Seleccione la interface
              </span>
              <select
                class="form-select"
                v-model="id_interfaz"
                ref="id_interfaz"
                :change="cargarTrafico()"
              >
                <option :value="null">Seleccionar interface</option>
                <option
                  v-for="interfaz in interfaces"
                  :key="interfaz.id"
                  :value="interfaz.id"
                >
                  {{ interfaz.name }}
                </option>
              </select>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <div class="input-group">
                <span class="input-group-text">
                  Mostar datos
                </span>
                <select
                  class="form-select"
                  v-model="mostrar_tipo"
                  ref="id_interfaz"
                  v-on:click="dibujarGrafica()"
                >
                  <option :value="1">Mostrar ambos</option>
                  <option :value="2">Transferencia (tx)</option>
                  <option :value="3">Recepción (rx)</option>
                </select>
                </div>
              </div>

              <div class="col-md-6 mt-2 text-center">
                <span
                  class="badge font-size-16 badge-transferencia"
                  v-if="mostrar_tipo == 2 || mostrar_tipo == 1"
                >
                  Transferencia (tx)
                </span>
                <span
                  class="badge font-size-16 badge-recepcion"
                  v-if="mostrar_tipo == 3 || mostrar_tipo == 1"
                >
                  Recepción (rx)
                </span>
              </div>
            </div>

            <br>

            <apexchart
              class="apex-charts"
              height="380"
              dir="ltr"
              :series="series"
              :options="chartOptions"
            ></apexchart>
            
            <button @click="preguntaReiniciarRouter()"
              class="btn btn-outline-info">
              <i class="mdi mdi-refresh"></i>
              Reiniciar router
            </button>
          </div>
        </div>

        <div class="text-right">
          <button class="btn btn-secondary" @click="cerrar()">
            <i class="mdi mdi-chevron-left"></i>
            Atrás
          </button>
        </div>
      </div>
    </div>
    <br>
  </layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import RouterSrv from '@/services/RouterSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'TableroRouter',
  components: { Layout },
  data() {
    return {
      router: {
        id: null,
        nombre: '',
        ip: '',
        descripcion: '',
        usuario: '',
        clave: '',
        puerto_api: 8728,
        puerto_web_proxy: 8080,
        usuario_bloqueado: true,
        clave_bloqueada: true,
        puerto_api_bloqueado: true,
        puerto_web_proxy_bloqueado:true,
        latitud: 0,
        longitud: 0,
        id_sitio: null,
        ifs_sinc: 0,
        bs_sinc: 0,
        pv_sinc: 0,
        intentos_conexion_fallidos_api: 0,
        proximo_intento_conexion_api: null,
        dns1_sugerido: null,
        dns2_sugerido: null,
        registro_en_log : false,
        cantidad_cpes: {
          total: 0,
          sincronizados: 0,
          por_sincronizar: 0
        }
      },
      interfaces: [],
      id_interfaz: null,
      traficos: [
        {
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        }
      ],
      mostrar_tipo: 1,
      intervals: {
        actualizacion: null
      },
      series: [
        {
          name: "",
          data: []
        }
      ],
      // configuracion de la grafica
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
        },
        markers: {
          size: 4
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        colors: ["#18cc93","#32cbfd"],
        title: {
          text: "",
          align: "left",
          style: {
            fontWeight: 500
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: [ /*"Ene","Feb","Mar", ...*/],
          tickAmount: 10,
          tooltip: {
            enabled: false
          }
        }
      }
    }
  },

  created: function() {
    var self = this

    // Determinación del modo
    self.cargarRouter()
    self.intervals.actualizacion = setInterval(function() {
      self.cargarTrafico()
    }, 5000)
  },

  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },

  watch: {
    id_interfaz: function (newId, oldId) {
      var self = this
      if(newId == oldId) return

      self.traficos = [
        {
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        },{
          id_interfaz:null,
          nombre_interfaz:"",
          rx_bps:0, tx_bps:0,
          time:"00:00:00"
        }
      ]
    }
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cargarRouter: function() {
      var self = this

      RouterSrv.routerJSON(self.$route.params.id).then(response => {
        let router = response.data
        Object.assign(self.router, router)

        self.cargarInterfaces(router.id)

      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarInterfaces: function(idRouter) {
      var self = this

      RouterSrv.interfacesJSON(idRouter).then(response => {
        self.interfaces = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las interfaces'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTrafico: function() {
      var self = this
      let idInterfaz = self.id_interfaz
      let idRouter = self.router.id

      if(idRouter == null || idInterfaz == null) {
        self.traficos = [
          {
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          },{
            id_interfaz:null,
            nombre_interfaz:"",
            rx_bps:0, tx_bps:0,
            time:"00:00:00"
          }
        ]
        return
      }

      RouterSrv.traficoInterfazJSON(idRouter, idInterfaz).then(response => {
        let trafico = response.data
        var time = new Date()
        Object.assign(trafico,{
          time: time.getHours()+':'+time.getMinutes()+':'+time.getSeconds()
        })
        self.traficos.push(trafico)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar el trafico de la interfaz'
        }
        // iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.dibujarGrafica()
      })
    },

    cerrar: function() {
      this.atras()
    },

    dibujarGrafica() {
      var self = this
      
      let tx = []
      let rx = []
      let labels = []

      if(self.traficos.length > 15) self.traficos.shift()

      self.traficos.forEach((trafico,index) => {
        tx.push((trafico.tx_bps*0.000001).toFixed(3))
        rx.push((trafico.rx_bps*0.000001).toFixed(3))
        labels.push(trafico.time)
      })

      var series= []
      var colors= []

      switch (self.mostrar_tipo) {
        case 1:
        series = [
            {
              name: "Transferencia (tx)",
              data: tx
            },{
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = ["#18cc93","#32cbfd"]
        break;
        case 2:
          series = [
            {
              name: 'Transferencia (tx)',
              data: tx
            }
          ]
          colors = ["#18cc93"]
        break;
        case 3:
          series = [
            {
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = [ "#32cbfd" ]
        break;
      }

      self.series = series
      self.chartOptions = {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: colors,
        title: {
          text: "Consumo en tiempo real",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: labels,
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        },
      }
    },

    preguntaReiniciarRouter() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Reiniciar router",
        html: '¿Estás seguro que deseas reiniciar el router?<br><br> Esta acción apagará y encenderá nuevamente el router',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          RouterSrv.reiniciar(self.router.id).then(repsonse => {
            swal.fire("Reinicio", "Se mandó la instrucción de reinicio", "success");
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudieron cargar el trafico de la interfaz'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }   
  }
}
</script>

<style scoped>
.badge-transferencia {
  background-color: #1ACC93;
  color: white;
}

.badge-recepcion {
  background-color: #32CBFD;
  color: white;
}
</style>